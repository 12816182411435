import React, { FC, useEffect, useState } from "react";
//@ts-ignore
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import { HomeSearchContainer } from "../modules/home-search/home-search.container";
import { RecommendedServicesContainer } from "../modules/recommended-services/recommended-services.container";
import { RelevantServicesContainer } from "../modules/relevant-services/relevant-services.container";
import { NewsContainer } from "../modules/news/news.container";
import { AppsContainer } from "../modules/apps/apps.container";
import { CategoryContainer } from "../modules/category/category.container";
import { LinkBarContainer } from "../modules/link-bar/link-bar.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { IndicatorsContainer } from "../modules/indicators/indicators.container";
import { OmbudsmanContainer } from "../modules/ombudsman/ombudsman.container";
import { TopServicesContainer } from "../modules/top-services/top-services.container";
import { Popup } from "../components/popup";
import usePopup from "../hooks/usePopup";
import { getPopupData } from "../apis/news-rss";

export const Home: FC = () => {
  const { open, popup } = usePopup("catalog-home");
  const [popupData, setPopupData] = useState({ link: "", image: "" });

  useEffect(() => {
   open();

  //  getPopupData().then(data => {
  //    if (data) {
  //     setPopupData({
  //       link: data.link,
  //       image: data.image
  //     });
  //     open();
  //    }
  //  });
  }, []);

  return (
    <>
      {/* {popupData.link && popupData.image && (
        <Popup.Root
          className="rematricula-popup"
          open={popup.isOpen}
          onOk={popup.close}
          onCancel={popup.close}
          footer={null}
          closeIcon={<span>Fechar [x]</span>}
        >
          <a
            className="rematricula-popup__content"
            href={popupData.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={580} src={popupData.image} alt="aviso" />
          </a>
        </Popup.Root>
      )} */}

      <Popup.Root
          className="rematricula-popup"
          open={popup.isOpen} 
          onOk={popup.close}
          onCancel={popup.close}
          footer={null}
          closeIcon={<span>Fechar [x]</span>}>

          <a className="rematricula-popup__content" href={'https://form.respondi.app/NFfS5Ex9'} target="_blank" rel="noopener noreferrer">
            <img height={580} src={`${process.env.PUBLIC_URL}/images/O_Governo_de_Mato_Grosso_esta_concorrendo_ao_Premio_Digital_Transformation_Awards_pelo_voto_popular_na_categoria_Governo_Estadual_Transformador.png`} alt="aviso" />
          </a>
      </Popup.Root>

      <Element name="search">
        <HomeSearchContainer />
      </Element>

      <LinkBarContainer />

      <Element name="recommendedServices">
        <RecommendedServicesContainer />
      </Element>

      <Element name="relevantServices">
        <RelevantServicesContainer />
      </Element>

      <Element name="topServices">
        <TopServicesContainer />
      </Element>

      <Element name="newsContainer">
        <NewsContainer />
      </Element>

      <Element name="appsContainer">
        <AppsContainer />
      </Element>

      <Element name="category">
        <CategoryContainer />
      </Element>

      <Element name="ombudsman">
        <OmbudsmanContainer />
      </Element>

      <Element name="indicators">
        <IndicatorsContainer />
      </Element>

      <FooterContainer />
    </>
  );
};
